import React from "react";
import { useNavigate } from "react-router-dom";
export default function GardenSection() {
  const navigate = useNavigate(); // Get the navigate function

  const handleButtonClick = () => {
    // Navigate to the contact page
    navigate("/garden");
  };
  return (
    <div className="pt-20 pb-4 lg:py-20 mx-auto sm:w-auto px-6 sm:px-20 md:px-24 bg-headerColor">
      <h1 className="text-4xl font-bold pb-10 ">GartenBau</h1>
      <div className="flex lg:flex-row flex-col lg:w-auto mx-auto">
        <div className="lg:w-1/2 mb-8">
          {/* Div-i i parë me një foto */}
          <img
            src="assets/home/14.jpg"
            alt="Foto"
            className="lg:w-full lg:h-80 xl:h-96 object-cover rounded-3xl"
          />
        </div>
        <div className="lg:w-1/2 lg:pl-10 xl:pl-28">
          {/* Div-i i dytë me paragrafin dhe butonin */}
          <p className="text-base mb-4 text-left break-words">
            Mit einem starken Fundament in der
            Hausmeister-Dienstleistungsbranche haben wir unsere Fähigkeiten
            verfeinert, um stets außergewöhnliche Ergebnisse zu liefern. Bei der
            Umgestaltung von Außenbereichen und der Gestaltung von Gärten, die
            Ihre Vision widerspiegeln, sticht unsere Expertise hervor. Von der
            Konzeption bis zur Umsetzung setzen wir uns dafür ein, dass Ihre
            Gartenträume lebendige Realität werden. Lassen Sie uns
            zusammenarbeiten und Ihren Garten zum Leben erwecken!
          </p>
          <div className="w-full flex mx-auto">
            <button
              type="button"
              className="text-white bg-green font-medium rounded-full w-44 sm:w-64 h-12 mx-auto lg:mx-0 text-base sm:text-xl px-5 py-2.5 text-center mb-2"
              onClick={handleButtonClick}
            >
              GARTEN BAU
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

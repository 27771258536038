import React from "react";
import Header from "../header/header";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/footer";

const NotFound = () => {
  const navigate = useNavigate(); // Get the navigate function

  const handleButtonClick = () => {
    // Navigate to the contact page
    navigate("/");
  };
  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100 px-8">
        <h1 className="text-4xl font-bold mb-4 text-gray-800">
          404 - Page not found
        </h1>
        <p className="text-gray-600 mb-6">The page is not available.</p>
        <button
          className="bg-lightgreen hover:bg-green text-white font-semibold py-2 px-6 rounded-full focus:outline-none"
          onClick={handleButtonClick}
        >
          Return to the main page
        </button>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;

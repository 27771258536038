import { useNavigate } from "react-router";

export default function RenovationSection() {
  const navigate = useNavigate(); // Get the navigate function

  const handleButtonClick = () => {
    // Navigate to the contact page
    navigate("/renovation");
  };
  return (
    <div className="py-20  mx-auto sm:w-auto px-6 sm:px-20 md:px-24 bg-headerColor">
      <h1 className="text-4xl font-bold pb-10 break-words">Renovierung</h1>
      <div className="flex lg:flex-row flex-col-reverse lg:w-auto mx-auto">
        <div className="lg:w-1/2 lg:pr-10 xl:pr-28">
          {/* Div-i i dytë me paragrafin dhe butonin */}
          <p className="text-base mb-4 text-left break-words">
            Mit einem soliden Fundament in der
            Hausmeister-Dienstleistungsbranche haben wir unsere Fähigkeiten
            verfeinert, um stets außergewöhnliche Ergebnisse zu liefern. Das
            Bauen und Renovieren von Häusern, die Ihre Vision widerspiegeln, und
            die Gestaltung funktionaler Räume sind unsere wahre Leidenschaft.
            Unser Ziel ist es, Ihre Wünsche in die Realität umzusetzen. Lassen
            Sie uns zusammenarbeiten und gemeinsam das Außergewöhnliche
            erreichen!
          </p>
          <div className="w-full flex mx-auto">
            <button
              type="button"
              className="text-white bg-green font-medium rounded-full w-44 sm:w-64 h-12 mx-auto lg:mx-0 text-base sm:text-xl px-5 py-2.5 text-center mb-2"
              onClick={handleButtonClick}
            >
              RENOVIERUNG
            </button>
          </div>
        </div>
        <div className="lg:w-1/2 mb-8">
          {/* Div-i i parë me një foto */}
          <img
            src="assets/home/8.jpg"
            alt="Foto"
            className="lg:w-full lg:h-80 xl:h-96 object-cover rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}

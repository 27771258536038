import React from 'react'
const simulateCall = (phoneNumber) => window.open(`tel:${phoneNumber}`, '_self')
const callNumber = () => {
  simulateCall('+49 177 7200 746')
}
const HomeBanner = () => {
  const text = 'Naser Zeqiri'
  return (
    <div className='relative h-screen bg-gray-100'>
      <img
        className='w-full h-full object-cover'
        src={'assets/home/9.jpg'}
        alt='Banner'
      />
      <div className='absolute inset-0 bg-black bg-opacity-30 flex justify-center lg:justify-start items-center'>
        <div className='h-24 sm:h-56 lg:h-60 mx-20'>
          <h1 className='text-white text-[40px] sm:text-[50px] md:text-[70px] text-center lg:text-left leading-[2.8rem] sm:leading-[4rem] md:leading-[6rem] font-bold'>
            {text ? text : ''}
          </h1>
          <h2 className='text-white text-center lg:text-left text-[18px] sm:text-[30px] md:text-[40px] font-sans leading-[1.8rem] sm:leading-[2.5rem] md:leading-[3.1rem]'>
            GALA BAU & <br /> HAUSMEISTERSERVICE
          </h2>
          <div className='w-full flex mx-auto'>
            <button
              type='button'
              onClick={callNumber}
              className='text-white bg-green font-medium rounded-full w-44 sm:w-64 h-12 mx-auto lg:mx-0 text-base sm:text-xl px-5 py-2.5 text-center mt-6 mb-2'
            >
              JETZT ANRUFEN
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomeBanner

import { useEffect } from 'react'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import AboutUs from '../components/home/about_us'
import HomeBanner from '../components/home/banner'
import ContactSection from '../components/home/contact-section'
import GardenSection from '../components/home/garden_section'
import RenovationSection from '../components/home/renovation_section'
export default function Home() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header />
      <HomeBanner />
      <AboutUs />
      <GardenSection />
      <RenovationSection />
      <ContactSection />
      <Footer />
    </div>
  )
}

import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Home from "../views/home";
import Contact from "../views/contact";
import Garden from "../views/garden";
import Renovation from "../views/renovation";
import PrivacyPolicy from "../views/privacy_policy";
import NotFound from "../components/error404/not_found";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
    errorElement: <h1>error page</h1>,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/garden",
    element: <Garden />,
  },
  {
    path: "/renovation",
    element: <Renovation />,
  },
  {
    path: "*",
    element: <NotFound />,
  },

  { path: "/privacy-policy", element: <PrivacyPolicy /> },
]);

export default router;

import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }
  const navigate = useNavigate()

  const handleLogoClick = () => {
    // Navigate to the homepage when the logo is clicked
    navigate('/')
  }

  return (
    <div className='fixed top-0 z-20 px-10 sm:px-20 bg-headerColor bg-opacity-70 backdrop-blur-md w-full'>
      <header className='w-full '>
        <div className='flex justify-between flex-row h-24 w-full'>
          <div
            onClick={handleLogoClick}
            role='link'
            className='flex justify-start items-center w-14 sm:w-16 md:w-20'
          >
            <img
              src='assets/home/logo.svg'
              alt='logo'
              className='w-14 h-14 sm:w-16 sm:h-16 md:w-20 md:h-20 mt-0.5 md:mt-1  cursor-pointer'
            />
          </div>
          <div className='w-3/5 items-center hidden md:flex justify-end text-right mr-0 text-base lg:text-lg text-black font-bold'>
            <div className='px-6 lg:px-10'>
              <Link
                to='/'
                className={` hover:border-b-4 hover:border-green transition-all ${
                  location.pathname === '/' ? ' border-b-4 border-green' : ''
                }`}
              >
                HOME
              </Link>
            </div>
            <div className='px-6 lg:px-10'>
              <Link
                to='/garden'
                className={`hover:border-b-4 hover:border-green transition-all ${
                  location.pathname === '/garden'
                    ? ' border-b-4 border-green'
                    : ''
                }`}
              >
                GARTEN
              </Link>
            </div>
            <div className='px-6 lg:px-10'>
              <Link
                to='/renovation'
                className={`hover:border-b-4 hover:border-green transition-all ${
                  location.pathname === '/renovation'
                    ? ' border-b-4 border-green'
                    : ''
                }`}
              >
                RENOVIERUNG
              </Link>
            </div>
            <div className='px-6 lg:px-10'>
              <Link
                to='/contact'
                className={`hover:border-b-4 hover:border-green transition-all ${
                  location.pathname === '/contact'
                    ? ' border-b-4 border-green'
                    : ''
                }`}
              >
                KONTAKT
              </Link>
            </div>
          </div>
          <div className='flex justify-center items-center md:hidden'>
            {menuOpen ? (
              ''
            ) : (
              <button
                onClick={toggleMenu}
                className='text-3xl text-black font-bold  focus:outline-none'
              >
                ☰
              </button>
            )}
          </div>
        </div>
      </header>
      <div
        className={`fixed top-0 right-0 h-80 w-52 bg-headerColor bg-opacity-70 backdrop-blur-md transform transition-transform ease-in-out duration-300 ${
          menuOpen ? 'translate-x-0' : 'translate-x-full'
        } md:hidden z-30`}
      >
        <div className='flex flex-col items-start pt-5 px-6 space-y-6'>
          <button
            onClick={toggleMenu}
            className='text-2xl text-black font-bold px-4 focus:outline-none'
          >
            ✕
          </button>
          <Link
            to='/'
            className={` text-lg font-bold hover:border-b-4 hover:border-green transition-all  ${
              location.pathname === '/' ? ' border-b-4 border-green pl-0' : ''
            }`}
            onClick={closeMenu}
          >
            HOME
          </Link>
          <Link
            to='/garden'
            className={` text-lg font-bold hover:border-b-4 hover:border-green transition-all  ${
              location.pathname === '/garden'
                ? ' border-b-4 border-green pl-0'
                : ''
            }`}
            onClick={closeMenu}
          >
            GARTEN
          </Link>
          <Link
            to='/renovation'
            className={` text-lg font-bold hover:border-b-4 hover:border-green transition-all  ${
              location.pathname === '/renovation'
                ? ' border-b-4 border-green pl-0'
                : ''
            }`}
            onClick={closeMenu}
          >
            RENOVIERUNG
          </Link>
          <Link
            to='/contact'
            className={` text-lg font-bold hover:border-b-4 hover:border-green transition-all  ${
              location.pathname === '/contact'
                ? ' border-b-4 border-green pl-0'
                : ''
            }`}
            onClick={closeMenu}
          >
            KONTAKT
          </Link>
        </div>
      </div>
    </div>
  )
}

export default function ContactSection({ contact }) {
  return (
    <div
      className={` bg-lightgreen mx-auto ${contact === true ? ' mt-24' : ''}`}
    >
      <div className='flex md:flex-row flex-col w-full '>
        <div className='w-3/4 mx-auto md:w-1/2 md:pl-24 my-10 md:my-auto'>
          {/*  */}
          {/* Div-i i dytë me paragrafin dhe butonin */}
          <h1 className='text-4xl font-bold pb-10 break-words'>
            Kontaktieren Sie uns
          </h1>
          <h1 className='font-bold'>ADRESSE</h1>
          <p className='text-base mb-4 text-left break-words'>
            Mülheimer Str.270, 46045 Oberhausen
          </p>
          <h1 className='font-bold'>E-MAIL ADRESSE</h1>

          <button
            className='text-base mb-4 text-left break-words text-blue hover:text-green active:text-green underline'
            onClick={() =>
              (window.location = 'mailto:exklusivsanierung@gmail.com')
            }
          >
            exklusivsanierung@gmail.com
          </button>
          {/* <p className='text-base mb-4 text-left break-words'>
            exklusivsanierung@gmail.com
          </p> */}
          <h1 className='font-bold'>TELEFONNUMMER</h1>
          <p className='text-base mb-4 text-left break-words'>0177 7200 746</p>
          <h1 className='font-bold'>FACEBOOK</h1>
          <div className=' mb-4'>
            <a
              className=' text-blue underline hover:text-green active:text-green hover:underline text-base  text-left break-words'
              href='https://www.facebook.com/nz.gala.bau'
              target='_blank'
              rel='noopener noreferrer'
            >
              Naser Zeqiri Gala Bau
            </a>
          </div>

          <h1 className='font-bold'>INSTAGRAM</h1>
          <div className=' mb-4'>
            <a
              className=' text-blue underline hover:text-green active:text-green hover:underline text-base  text-left break-words'
              href='https://www.instagram.com/nz.gala.bau'
              target='_blank'
              rel='noopener noreferrer'
            >
              Naser Zeqiri Gala Bau
            </a>
          </div>
        </div>
        <div className='md:w-1/2 h-auto'>
          {/* Div-i i parë me një foto */}
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.015925969777!2d6.86143367661819!3d51.47622197180626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c02cb86b5a0b%3A0x36de2f2be152e5df!2sM%C3%BClheimer%20Str.%2098%2C%2046045%20Oberhausen%2C%20Germany!5e0!3m2!1sen!2s!4v1693008825547!5m2!1sen!2s'
            className='w-full'
            // width="400"
            height='500'
            style={{ border: '0', borderRadius: '0.2rem' }}
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
            title='Google Map Location'
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default function AboutUs() {
  return (
    <div className="flex justify-center items-center py-24 md:py-32 text-center bg-lightgreen">
      <div className="w-3/4 md:w-1/2">
        <h1 className="text-4xl font-bold pb-10 ">Über Uns</h1>
        <p className="text-left text-xl break-words font-serif">
          Als Experten im Garten- und Landschaftsbau setzen wir höchste Maßstäbe
          für unsere Leistungen. Qualität und Engagement stehen bei uns an
          erster Stelle. Unsere Homepage informiert Sie umfassend über unsere
          Dienstleistungen und unsere Unternehmensphilosophie. Unsere
          Fachgebiete sind die Neuanlage und Umgestaltung von Hausgärten. Wir
          planen und realisieren Teichanlagen, Wasserläufe, Pflanzungen,
          Terrassen und Sitzplätze. Durch moderne Technologien und effiziente
          Arbeitsmethoden können wir Ihre Träume vom perfekten Garten und
          Zuhause realisieren. Unsere Arbeit geht über Handwerk hinaus, wir
          schaffen Räume, die Ihre Persönlichkeit widerspiegeln. Unser Ziel ist
          es, nicht nur zu bauen und zu renovieren, sondern auch Emotionen und
          Träume zu verwirklichen. Wir setzen auf höchste Qualität und bieten
          beste Kundenerfahrung. Wir verwandeln Grünflächen in blühende Oasen
          mit modernen Gestaltungstechniken und innovativen Lösungen. Die
          Renovierung von Wohnhäusern liegt uns ebenso am Herzen, wir gestalten
          Räume, die Ihren Bedürfnissen entsprechen. Für uns zählt jedes Detail.
          Mit Leidenschaft schaffen wir eine Welt, die Ihre Werte und Vorlieben
          widerspiegelt.
        </p>
      </div>
    </div>
  );
}

import React from 'react'

const PrivacyPolicyComponent = () => {
  return (
    <div className='container mx-auto p-4'>
      <h1 className='text-2xl font-semibold mb-4'>Privacy Policy</h1>
      <p className='mb-4'>
        Your privacy is important to us. We don't use cookies, collect personal
        information, or store any data from your interactions with Garten Bau.
      </p>
      <p className='mb-4'>
        We are committed to ensuring your information is safe and secure during
        your visit to our website.
      </p>
      <p className='mb-4'>
        If you have any questions about our privacy practices, feel free to
        contact us.
      </p>
    </div>
  )
}

export default PrivacyPolicyComponent

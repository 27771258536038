import { useState } from 'react'

export default function Galerie({ garden, renovation }) {
  const gardenGalerie = [
    {
      img: 'assets/garden/1.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/2.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/3.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/4.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/5.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/6.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/7.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/8.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/home/9.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/10.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/11.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/12.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/13.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/home/14.jpg',
      title: 'Garten-Galerie',
    },
    {
      img: 'assets/garden/15.jpg',
      title: 'Garten-Galerie',
    },
  ]
  const renovationGalerie = [
    {
      img: 'assets/renovation/1.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/2.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/3.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/4.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/5.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/6.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/7.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/home/8.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/9.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/10.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/11.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/12.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/13.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/14.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/15.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/16.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/17.jpg',
      title: 'Renovierung-Galerie',
    },
    {
      img: 'assets/renovation/18.jpg',
      title: 'Renovierung-Galerie',
    },
  ]
  const [selectedImage, setSelectedImage] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <div
      className={`flex justify-start flex-col items-center px-10 md:px-40 lg:px-52 h-full pt-40 ${
        garden === true ? 'bg-lightgreen' : 'bg-lightbrown'
      }`}
    >
      <div className='h-10 mb-3 sm:mb-0 justify-center w-full text-center'>
        <h2 className='text-white font-semibold lg:mx-10 break-words text-center lg:text-center text-[30px] sm:text-[36px] md:text-[40px] font-sans leading-[1.8rem] sm:leading-[2.5rem] md:leading-[3.1rem]'>
          {garden === true ? 'Garten Bau Galerie' : 'Renovierung Galerie'}
        </h2>
      </div>
      <div className='w-full h-full flex justify-center items-center'>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 items-center content-center px-0 py-10 w-full h-full'>
          {garden === true &&
            gardenGalerie.map((item, i) => (
              <div className='flex h-full w-full'>
                <div key={i} className='max-h-60 w-full'>
                  <img
                    className='rounded-md w-full h-full object-cover'
                    src={item.img}
                    alt={item.title}
                    onClick={() => {
                      setSelectedImage(item.img)
                      setModalIsOpen(true)
                    }}
                  />
                  {modalIsOpen && (
                    <div className='fixed top-24 left-0 right-0 bottom-0 flex justify-center items-center z-50'>
                      <div
                        className='fixed top-24 left-0 right-0 bottom-0 bg-black opacity-10 '
                        onClick={() => {
                          setSelectedImage(null)
                          setModalIsOpen(false)
                        }}
                      />
                      <div className='relative z-50 mx-10 md:p-0 w-full md:w-2/3 lg:w-1/2 h-auto  m-10 flex justify-center items-center'>
                        <img
                          src={selectedImage}
                          alt='Selected Image'
                          className='w-auto rounded-lg  max-h-[800px] '
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          {renovation === true &&
            renovationGalerie.map((item, i) => (
              <div className='flex h-full w-full'>
                <div key={i} className='max-h-60 w-full'>
                  <img
                    className='rounded-md w-full h-full object-cover'
                    src={item.img}
                    alt={item.title}
                    onClick={() => {
                      setSelectedImage(item.img)
                      setModalIsOpen(true)
                    }}
                  />
                  {modalIsOpen && (
                    <div className='fixed top-24 left-0 right-0 bottom-0 flex justify-center items-center z-50'>
                      <div
                        className='fixed top-24 left-0 right-0 bottom-0 bg-black opacity-10'
                        onClick={() => {
                          setSelectedImage(null)
                          setModalIsOpen(false)
                        }}
                      />
                      <div className='relative z-50 mx-10 md:p-0 w-full md:w-2/3 lg:w-1/2 h-auto rounded-lg shadow-lg m-10'>
                        <img
                          src={selectedImage}
                          alt='Selected Image'
                          className='w-full rounded-lg h-auto '
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import Footer from '../components/footer/footer'
import Galerie from '../components/galerie'
import Header from '../components/header/header'

export default function Garden() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header />
      <Galerie garden={true} />
      <Footer />
    </div>
  )
}

import { useEffect } from 'react'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import ContactSection from '../components/home/contact-section'

export default function Contact() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header />
      <ContactSection contact={true} />
      <Footer />
    </div>
  )
}

import { useNavigate } from "react-router-dom";
export default function Footer({ renovation }) {
  const navigate = useNavigate(); // Get the navigate function

  const handleButtonClick = () => {
    // Navigate to the contact page
    navigate("/privacy-policy");
  };
  return (
    <div
      className={`flex flex-col text-white text-center px-4 sm:px-20 md:px-28 ${
        renovation === true ? "bg-darkbrown" : "  bg-darkgreen"
      }`}
    >
      <div className="flex flex-col justify-center w-full xl:px-10">
        <div className="flex flex-col lg:flex-row lg:justify-between pt-6 lg:py-8">
          <div className="px-2 pb-8 lg:pb-0">
            <h2 className="text-2xl">TELEFON</h2>
            <p className="sm:text-lg">0177 7200 746</p>
          </div>
          <div className="px-2 pb-8 lg:pb-0">
            <h2 className="text-2xl">E-MAIL</h2>
            <p className="text-[13px] watch:text-base sm:text-lg break-words">
              exklusivsanierung@gmail.com
            </p>
          </div>
          <div className="px-2 pb-8 lg:pb-0">
            <h2 className="text-2xl">ADRESSE</h2>
            <p className="sm:text-lg">Mülheimer Str.270, 46045 Oberhausen</p>
          </div>
        </div>
        <div className="flex">
          <hr
            className={`w-full h-1 ${
              renovation === true ? "bg-lightbrown" : "  bg-lightgreen"
            } `}
          ></hr>
        </div>
      </div>
      <div className="flex flex-row justify-center pt-10 lg:pt-16 pb-6 ">
        <p>
          Copyright ©2023 <b>NZ GalaBau & Hausmeisterservice</b>. | Developed By
          <b> gm</b>.{" "}
          <span className="underline" onClick={handleButtonClick}>
            {" "}
            Datenschutzerklärung
          </span>
        </p>
      </div>
    </div>
  );
}
